<template>
  <v-container fluid>
    <v-data-table
      :headers="headers"
      :items="rows"
      :loading="loading"
      :items-per-page="-1"
      hide-default-footer
      class="elevation-1"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
    >
      <!-- eslint-disable-next-line -->
      <template v-slot:item.ym="{ item }">
        {{ formatDate(item.ym) }}
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import gql from "graphql-tag";
import dayjs from "dayjs";

export default {
  name: "Analytics",

  methods: {
    formatDate(date) {
      return dayjs(date).format("MMMM YYYY");
    },
  },

  async mounted() {
    /** @namespace data.households **/
    let hquery = await this.$apollo.query({
      query: gql`
        query Analytics {
          households(where: { campaign_id: { _is_null: false } }) {
            people_aggregate {
              aggregate {
                count
              }
            }
            id
            campaign {
              id
              events(limit: 1, order_by: { start_date: asc }) {
                id
                start_date
              }
            }
          }
        }
      `,
    });

    let kd = {};

    for (const h of hquery.data.households) {
      let date = dayjs(h.campaign.events[0].start_date);
      let key = date.format("YYYY-MM");
      if (key in kd) {
        kd[key]["people"] += h.people_aggregate.aggregate.count;
        kd[key]["households"] += 1;
      } else {
        kd[key] = {
          people: h.people_aggregate.aggregate.count,
          households: 1,
          campaigns: 0,
          requestedCount: 0,
        };
      }
    }

    let cquery = await this.$apollo.query({
      query: gql`
        query CampaignsAnalytics {
          campaigns(where: { status: { _eq: "complete" } }) {
            id
            requested_count
            events(limit: 1, order_by: { start_date: asc }) {
              id
              start_date
            }
          }
        }
      `,
    });

    for (const c of cquery.data.campaigns) {
      let date = dayjs(c.events[0].start_date);
      let key = date.format("YYYY-MM");
      if (key in kd) {
        kd[key]["campaigns"] += 1;
        kd[key]["requestedCount"] += c.requested_count;
      } else {
        kd[key] = {
          people: 0,
          households: 0,
          campaigns: 1,
          requestedCount: c.requested_count,
        };
      }
    }

    console.log(kd);

    for (const property in kd) {
      this.rows.push({
        ym: property,
        people: kd[property]["people"],
        households: kd[property]["households"],
        requestedCount: kd[property]["requestedCount"],
        campaigns: kd[property]["campaigns"],
        convert:
          Math.round(
            (kd[property]["people"] / kd[property]["requestedCount"]) * 100000
          ) / 1000,
      });
    }

    this.loading = false;
  },

  computed: {},

  data() {
    return {
      headers: [
        { text: "Month & Year", value: "ym" },
        { text: "Campaigns", value: "campaigns" },
        { text: "Mailed", value: "requestedCount" },
        { text: "Households", value: "households" },
        { text: "People", value: "people" },
        { text: "Conversion", value: "convert" },
      ],
      rows: [],
      loading: true,
      sortBy: "ym",
      sortDesc: true,

      raw_rows: [],
    };
  },
};
</script>
